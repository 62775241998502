<template>
        <div class="commentsWrap">
            <div class="commentEditorWrap">
                <section class="commentEditor">
                    <header class="commentEditorHeader">
                        <a class="commentPrintLink cursor" @click="printNotes">Print</a>
                        <h4>{{notesTitle}}</h4>
                        <p>{{notesSubTitle}}</p>
                        <!-- <span class="commentCounter">{{notesCountTitle}}</span> -->
                        <a v-if="!newNoteActive" class="btn lineBtn commentTrigger cursor" @click="newNoteActive=true">Nieuwe opmerking</a>
                    </header>
                    <div class="commentEditorInputfieldWrap" :class="{active:newNoteActive}">
                        
                            <textarea @focus="isActive=true" :class="{active:isActive}" cols="" rows="" v-model="newNote" placeholder="Typ je bericht"></textarea>
                            <button @click="addNote();isActive=false;"><i class="fa-solid fa-paper-plane-top"></i></button>
                        
                    </div>
                </section>
            </div>
            <div class="commentEntriesWrap">
                <a v-if="notes.length" class="commentPrintLink cursor" @click="printNotes">Print</a>
                <section class="commentEntries">

                    
                    <div v-for="note in notes" class="commentEntry" :style="{'border-left-color':note.user.color}">
                        <header class="commentEntryHeader">
                            
                            <h4>{{note.user.fullname?note.user.fullname:'SYS'}}</h4>
                            <time class="commentDateTime">{{$filters.formatDate(note.create)}}</time>
                            <a @click="note._editable=1" class="commentEdit cursor">
                                <i class="fa-solid fa-pen"></i>
                            </a>
                            <a @click="confirmDeleteNote(note)" class="commentTrash">
                                <i class="fa-solid fa-trash cursor"></i>
                            </a>
                        </header>
                        <template v-if="note._editable">
                            <textarea v-model="note.text"></textarea>
                            <a @click="updateNote(note)" class="btn btnSaveCommentChanges">Wijzigingen opslaan</a>
                        </template>
                        <p v-else class="comment" v-html="$filters.nl2br(note.text)"></p>
                    </div>
                    
                </section>
            </div>
        </div>
</template>

<script>
export default {
   props: {
        _data: {type:Object, required:true},
    },
    data() {
        return {
            isActive: false,
            newNote: '',
            newNoteActive: false
        }
    },
    computed: {
        notes() {
            return this._data.Notes.filter(note=>{return !note._deleted})
        },
       notesCountTitle() {
            if (this._data.Notes.length==1) return '1 opmerking';
            return this._data.Notes.length+' opmerkingen'
       },
        notesTitle() {            
            switch(this._data._class) {
                case 'Enrollment':
                    return this._data.Participant.fullname;
                    break;
                case 'Course':
                    return this._data.name;
                    break;
                case 'Participant':
                    return this._data.fullname;
                    break;
                case 'Customer':
                    return this._data.name;
                    break;
                default:
                    return ''
            }
        },
        notesSubTitle() {
            var html = [];
            switch(this._data._class) {
                case 'Enrollment':
                    return this._data.Customer.name;
                    break;
                case 'Course':
                    return this._data.BaseCourse.acronym;
                    break;
                // case 'Participant':
                //     return 'klaas';
                //     break;
                default:
                    return ''
            }
        },
        printEntityName() {
            switch(this._data._class) {
                case 'Enrollment':
                    return "Inschrijving";
                    break;
                case 'Course':
                    return "Cursus";
                    break;
                case 'Customer':
                    return "Klant";
                    break;
                case 'Participant':
                    return "Cursist";
                    break;
                default:
                    return ''
            }
        }
    },
    methods: {
        confirmDeleteNote(note) {
            this.showDialog({
                title: 'Notitie verwijderen',
                text: "Weet je zeker dat je deze notitie wil verwijderen?",
                buttons:[
                    {label:'Annuleren'},
                    {label:'Verwijderen', action:()=>{this.deleteNote(note)}}
                ]
            })
            
        },
        async deleteNote(note) {
            this.closeDialog();
            const res = await this.callApi('delete', '/notes/'+note.id);
            note._deleted=true
        },
        async updateNote(note) {
            console.log('update note', note)
            const res = await this.callApi('put', '/notes/'+note.id, note);
            note._editable=false
        },
        async printNotes() {
            // var postData = {};
            // postData[this.$props._data._idfield] = this.$props._data.id;
            // postData['text'] = this.newNote;
            
            const res = await this.callApi('post', '/notes/getPdf', {
                entityName:this.notesTitle,
                entityTypeName:this.printEntityName,
                notes:this.notes});
            //window.open(process.env.VUE_APP_API_BASE+"/notes/showPrintedNotes", "_blank");
            window.open(process.env.VUE_APP_API_BASE+res.data.result, "_blank");
        },
        async addNote() {
            console.log('addNote', this.$props._data, this.newNote)
            var postData = {};
            postData[this.$props._data._idfield] = this.$props._data.id;
            postData['text'] = this.newNote;
            
            const res = await this.callApi('post', '/notes', postData);

            this.$props._data.Notes = res.data.result.Notes;
            this.newNote = '';
        }
    }
}
</script>

<style scoped>
.commentsWrap .commentEditor .commentEditorInputfieldWrap textarea:focus {
    height:2.6rem;
}
.commentsWrap .commentEditor .commentEditorInputfieldWrap textarea.active {
    height:20rem;
}
</style>