<script setup>
  const props = defineProps({
    context: Object,
  })
  //console.log('props:', props.context.attrs.url)
  function handleInput(e) {
    props.context.node.input(e.target.value)
  }
  function getUrl(index) {
    console.log('getUrl', index, props.context.attrs.url)
    if (index>=0) {
      console.log('isArray')
      if (Array.isArray(props.context.attrs.url)) return props.context.attrs.url[index];
    }
    if (props.context.attrs.url) return props.context.attrs.url;
    return false;
  }
  function openUrl(index) {
    console.log('openUrl', index, props.context)
    window.open(process.env.VUE_APP_API_BASE+getUrl(index), "_blank");

  }
  function hidden() {
      console.log(props.context)
      if (['wysiwyg'].includes(props.context.$formkit)) { return true;}
      return false;
  }
</script>

<template v-if="!hidden">

<!-- <label>{{$props.context.label}}</label> -->
  <span class="formkit-label">
    <template v-if="Array.isArray(props.context._value)">
      <template v-for="(item, index) in props.context._value">
        <a @click='openUrl(index);'>{{item}}</a>
      </template>
    </template>
    <template v-else>
      <a v-if="props.context.attrs.url" @click='openUrl(-1);'>{{props.context._value}}</a>
      <template v-else>{{props.context._value}}</template>
    </template>
  </span>
  
  
</template>