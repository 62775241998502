<template>

    <div class="panelForm" id="knowledgebaseSearchForm">
        
        <FormKit type="form" v-model="_entity" :actions="false" @submit="search" @keyup="search">
            <FormKit
            type="text"
            prefix-icon="search"
            v-model="value"
            label="Zoek een item in de kennisbank"
            />
        </FormKit>  
        <i v-if="isSearching" class="fa-solid fa-spinner-third fa-spin"></i>
        <div class="searchResults">
            <div v-for="result in results" class="searchResult" @click="showKnowledgebaseItem(result)">
                <h3>{{result.name}}</h3>
                <p>{{result.bodyStripped}}</p>
            </div>
        </div>
    </div>


</template>

<script>
import { search } from '@formkit/icons';

export default {
    data() {
        return {
            timeout: null,
            isSearching: false,
            value: '',
            results: [
                
            ]
        }
    },
    computed: {

    },
    methods: {
        showKnowledgebaseItem(item) {
            this.showDialog({
                title: item.name,
                text: [
                   item.body
                ].join('<br /><br />'),
                buttons:[
                    {label:'Sluiten'}
                ]
            })
        },
        search() {
            if (!this.value) return;
            // clear timeout variable
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                console.log('searching:', self.value);
                self.performSearch();
            }, 500);
        },  
        async performSearch() {
            this.isSearching = true;
            let res = await this.callApi('post', '/knowledgebaseitems/search', {query:this.value});
            this.results = res.data.result;
            this.isSearching = false;
            
        }
    },
    mounted() {
        document.getElementById('knowledgebaseSearchForm').querySelector('input').focus();
    }
}
</script>

<style scoped>
    /* .panelForm {
        padding: 1rem;
        background-color: #f9f9f9;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
    .searchResults {
        margin-top: 1rem;
    }
    .searchResult {
        padding: 1rem;
        background-color: #fff;
        border-radius: 5px;
        margin-bottom: 1rem;
    } */

    .searchResult {
        padding: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
    }
    .searchResult:hover {
        text-decoration: underline;
    }
    .searchResult h3 {
        font-size: 1.5rem;
    }
    .searchResult:nth-child(odd) {
        background: var(--bg-color)
    }
</style>